<template>
  <div class="page-layout">
    <gc-header headerData="largeWaterAnalysis">
      <template #ops>
        <el-button v-click-blur @click="handleExport">导 出</el-button>
      </template>
    </gc-header>
    <div class="content">
      <div class="left">
        <GcRegionTree
          v-model="form.areaId"
          showSelect
          lazy
          filterable
          :expandAll="false"
          :propsObj="{ label: 'name', isLeaf: 'leaf' }"
          :loadNode="handleLoadNode"
          :defaultExpandedKeys="['0']"
          @check-change="handleDeviceCheckChange"
        >
        </GcRegionTree>
      </div>
      <div class="right large-water-table">
        <div class="search-modal">
          <gc-custom-search
            class="search"
            width="430px"
            key-word="日期"
            type="date"
            date-picker-type="datetimerange"
            :search.sync="form.timeRange"
            valueFormat="yyyy-MM-dd HH:mm:ss"
            :clearable="false"
          >
            <template v-slot:icon>
              <i class="iconfont icon-riqi"></i>
            </template>
          </gc-custom-search>
          <gc-custom-search
            class="search"
            key-word="数据间隔"
            type="select"
            width="200px"
            :search-option="intervalOptions"
            :search.sync="form.interval"
          ></gc-custom-search>
          <el-button v-click-blur type="primary" class="query" @click="query"
            >查 询</el-button
          >
          <el-button v-click-blur @click="reset">重 置</el-button>
          <el-radio-group
            v-model="modeType"
            size="small"
            style="margin-left: auto"
          >
            <el-radio-button label="1">列表</el-radio-button>
            <el-radio-button label="2">图表</el-radio-button>
          </el-radio-group>
        </div>
        <div
          class="data-show-content"
          v-loading="loading"
          v-if="lineData.length > 0 && tableData.length > 0"
        >
          <div class="echart-modal" :class="{ hide: modeType === '1' }"></div>
          <div class="table-modal" :class="{ hide: modeType === '2' }">
            <el-table
              ref="tableRef"
              :data="tableData"
              style="width: 100%"
              height="100%"
              :header-cell-style="{
                background: '#EBEDFA',
                color: '#666A80',
                'border-color': 'rgba(170, 178, 193, 0.2)',
                'font-weight': 600,
              }"
              :border="true"
            >
              <el-table-column
                prop="index"
                label="序号"
                width="80"
                fixed="left"
              />
              <el-table-column
                prop="time"
                label="日期"
                width="200"
                fixed="left"
              />
              <el-table-column
                align="center"
                v-for="(item, index) in tableData[0].res || []"
                :key="index"
                :label="item.userName"
              >
                <el-table-column label="实际累计（m³）" align="center">
                  <el-table-column
                    label="起始读数"
                    minWidth="130"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.res[index].firstVal }} </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="结束读数"
                    minWidth="130"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.res[index].secondVal }} </span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    label="累计水量"
                    minWidth="130"
                    align="center"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.row.res[index].total }} </span>
                    </template>
                  </el-table-column>
                </el-table-column>
              </el-table-column>
            </el-table>
            <gc-pagination
              ref="pagination"
              :total="pagination.total"
              :page-size="pagination.pageSize"
              :currentPage="pagination.currentPage"
              :page-params="pagination"
              @current-page-change="currentPageChange"
            ></gc-pagination>
          </div>
        </div>
        <gc-empty v-else />
      </div>
    </div>
  </div>
</template>

<script>
import ResizeObserver from "resize-observer-polyfill";
import { init } from "echarts";
import {
  apiGetBigWatchRegionTree,
  apiGetDeviceNode,
  apiGetBigWatchTableList,
  apiGetBigWatchPreviewLine,
} from "@/api/statisticAnalysis";
import { exportReport } from "@/api/statisticAnalysis";

export default {
  data() {
    return {
      loading: false,
      modeType: "1",
      form: {
        areaId: null,
        timeRange: [],
        interval: null,
      },
      intervalOptions: [
        { label: "5分钟", value: 5 },
        { label: "15分钟", value: 15 },
        { label: "1小时", value: 60 },
        { label: "1天", value: 1440 },
      ],
      analysisInstance: null,
      tableData: [],
      treeData: [],
      checkList: [],
      pagination: {
        loading: false,
        currentPage: 1,
        total: 1,
        pageSize: 10,
      },
      lineData: [],
    };
  },
  created() {
    this.initSearchParams();
  },
  mounted() {
    this.$nextTick(() => {
      const resizeOb = new ResizeObserver(() => {
        this.analysisInstance && this.analysisInstance.resize();
      });

      resizeOb.observe(document.querySelector(".large-water-table"));
    });
  },
  methods: {
    initSearchParams() {
      const startTime = this.dayjs()
        .subtract(32, "day")
        .format("YYYY-MM-DD 00:00:00");
      const endTime = this.dayjs().format("YYYY-MM-DD 00:00:00");
      this.form.timeRange = [startTime, endTime];
      this.form.interval = 15;
    },

    query() {
      if (this.checkList.length == 0) {
        this.$message.info("请选择设备");
        return;
      }
      Promise.all([this.getLine(), this.getTableList(1)]).then(() => {
        if (this.lineData.length > 0) {
          this.$nextTick(() => {
            if (!this.analysisInstance) {
              this.analysisInstance = init(
                document.querySelector(".echart-modal")
              );
            }
            this.analysisInstance.clear();
            this.analysisInstance.setOption(this.packageLineOptions());
          });
        } else {
          this.analysisInstance = null;
        }
        this.$nextTick(() => {
          this.$refs.tableRef && this.$refs.tableRef.doLayout();
        });
      });
    },
    getLine() {
      return new Promise((resolve) => {
        apiGetBigWatchPreviewLine({
          ...this.form,
          startTime: this.form.timeRange[0],
          endTime: this.form.timeRange[1],
          deviceInfos: this.checkList.map((item) => {
            return {
              deviceId: item.deviceId,
              deviceName: item.deviceName,
            };
          }),
          timeRange: undefined,
        }).then((data) => {
          this.lineData = data || [];
          resolve(this.lineData);
        });
      });
    },
    getTableList(current) {
      return new Promise((resolve) => {
        this.pagination.currentPage = current;
        this.getRequest({
          current: this.pagination.currentPage,
          size: this.pagination.pageSize,
        }).then((res) => {
          this.pagination.total = Number(res.total);
          this.tableData = (res.records || []).map((item, index) => {
            return {
              ...item,
              index: index + 1,
            };
          });
          resolve(this.tableData);
        });
      });
    },
    getRequest(obj) {
      this.loading = true;
      let device = this.checkList.map((item) => {
        return {
          deviceId: item.deviceId,
          deviceName: item.deviceName,
        };
      });
      return new Promise((resolve) => {
        apiGetBigWatchTableList({
          ...obj,
          ...this.form,
          startTime: this.form.timeRange[0],
          endTime: this.form.timeRange[1],
          deviceInfos: device,
          timeRange: undefined,
        })
          .then((res) => {
            resolve(res);
          })
          .finally(() => {
            this.loading = false;
          });
      });
    },
    currentPageChange(pageNo) {
      this.getTableList(pageNo);
    },

    reset() {
      this.initSearchParams();
      this.query();
    },

    packageLineOptions() {
      let xData = this.lineData.map((item) => item.time);
      let seriesData = this.lineData[0].res.map((item) => {
        let yData = [];
        this.lineData.forEach((el) => {
          let findItem = el.res.find((m) => m.deviceId === item.deviceId);
          if (findItem) {
            yData.push(findItem.value);
          } else {
            yData.push(0);
          }
        });
        return {
          name: item.userName,
          type: "line",
          showSymbol: false,
          smooth: true,
          data: yData,
        };
      });
      let rate = (10 / this.lineData.length) * 100;

      return {
        color: ["#36B2F2", "#F9CB27", "#7A3FF8", "#2EE5DA", "#C0DF00"],
        dataZoom: [
          {
            show: true,
            realtime: true,
            start: 0,
            end: rate,
            height: 10,
            bottom: "3%",
          },
          {
            type: "inside",
            realtime: true,
            start: 0,
            end: rate,
          },
        ],
        tooltip: {
          trigger: "axis",
          axisPointer: {
            type: "cross",
            label: {
              backgroundColor: "#6a7985",
            },
          },
        },
        legend: {
          icon: "roundRect",
          itemWidth: 12,
          itemHeight: 12,
          textStyle: {
            color: "#000",
            fontSize: 10,
          },
          right: "2%",
        },
        grid: {
          left: "5%",
          right: "1%",
          bottom: "10%",
          top: "14%",
          containLabel: true,
        },
        xAxis: [
          {
            type: "category",
            axisLine: { show: false },
            axisTick: { show: false },
            axisLabel: {
              color: "#666666",
              fontSize: 12,
            },
            boundaryGap: false,
            data: xData,
          },
        ],
        yAxis: [
          {
            name: "单位：m³",
            type: "value",
          },
        ],
        series: seriesData,
      };
    },

    handleLoadNode(node, resolve) {
      if (node.level === 0) {
        this.getRegionTree().then((data) => {
          return resolve(data);
        });
      } else {
        let data = [];
        if (node.data.children && node.data.children.length > 0) {
          data.push(...node.data.children);
        }
        apiGetDeviceNode({
          waterRegionCode: node.data.code,
        }).then((res) => {
          let middleData = (res || []).map((item) => {
            return {
              ...item,
              name: item.userName,
              id: item.deviceId,
              code: item.deviceId,
              leaf: true,
            };
          });

          data.push(...middleData);
          resolve(data);
        });
      }
    },

    getRegionTree() {
      return new Promise((resolve) => {
        let params = {};
        apiGetBigWatchRegionTree(params).then((res) => {
          let middleData = res || [];
          this.treeData = this.removeNoDeviceNode(middleData);
          resolve(this.treeData);
        });
      });
    },

    // 移出没有设备的区域节点
    removeNoDeviceNode(arr) {
      let result = [];
      arr.forEach((item) => {
        item.disabled = true;
        if (item.children && item.children.length > 0) {
          item.children = this.removeNoDeviceNode(item.children);
        }
        if (item.dealFlag == 2) {
          result.push(item);
        }
      });
      return result;
    },

    // 设备选择
    handleDeviceCheckChange(list) {
      this.checkList = list || [];
      if (this.checkList.length > 0) {
        this.query();
      } else {
        this.tableData = [];
        this.lineData = [];
        this.analysisInstance = null;
      }
    },

    handleExport() {
      if (!this.tableData.length) {
        this.$message.warning("暂无表格数据");
        return;
      }
      let device = this.checkList.map((item) => {
        return {
          deviceId: item.deviceId,
          deviceName: item.deviceName,
        };
      });
      this.$toast("下载中，请稍后...");
      const data = {
        method: "post",
        url: "/monitor/water/largeScale/export/excel",
        fileName: "大表水量分析列表.xlsx",
        data: {
          ...this.form,
          startTime: this.form.timeRange[0],
          endTime: this.form.timeRange[1],
          deviceInfos: device,
        },
      };
      exportReport(data, "excel", "", this.$toast);
    },
  },
};
</script>
<style lang="scss" scoped>
.page-layout {
  height: 100%;
  display: flex;
  flex-direction: column;
  .content {
    flex: 1;
    height: 0;
    display: flex;
    .left {
      padding: 20px 12px;
      width: 300px;
      border-right: 1px solid #f1f1f1;
      height: 100%;
      overflow-y: scroll;
    }
    .right {
      flex: 1;
      width: 0;
      height: 100%;
      display: flex;
      flex-direction: column;
      padding: 20px 12px 6px;
      .search-modal {
        display: flex;
        align-items: center;
        .search {
          margin-right: 12px;
        }
      }
      .data-show-content {
        flex: 1;
        height: 0;
        position: relative;
        .echart-modal {
          position: absolute;
          top: 16px;
          left: 0;
          bottom: 20px;
          right: 0;
          z-index: 9;
          &.hide {
            opacity: 0;
            z-index: 0;
            pointer-events: none;
          }
        }
        .table-modal {
          border-radius: 8px 8px 0 0;
          display: flex;
          flex-direction: column;
          position: absolute;
          top: 16px;
          left: 0;
          bottom: 0;
          right: 0;
          z-index: 9;
          &.hide {
            opacity: 0;
            z-index: 0;
            pointer-events: none;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 600px) and (max-width: 1400px) {
  .page-layout {
    .content {
      .left {
        width: 280px;
      }
      .right {
        .data-show-content {
          .echart-modal {
            height: 240px;
            margin-top: 12px;
          }
        }
      }
    }
  }
}
</style>
